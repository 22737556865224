












































































import "weui";
import Vue from "vue";
import { Toast, Button, Icon, ActionSheet } from "vant";

enum InputType {
  Ebike,
  Controller,
  Charger,
  Battery,
  Cabinet,
  Box2,
  Room
}

export default Vue.extend({
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet
  },
  data() {
    return {
      inputType: 0,
      ebikeCode: "",
      controllerCode: "",
      chargerCode: "",
      batteryCode: "",
      cabinetCode: "",
      box2Code: "",
      roomCode: "",

      InputType: InputType,
      codeAction: {
        actions: [
          { name: "中控", inputType: InputType.Controller },
          { name: "电池", inputType: InputType.Battery },
          { name: "网关", inputType: InputType.Box2 },
          { name: "充电器", inputType: InputType.Charger },
          { name: "车架号", inputType: InputType.Ebike },
          { name: "充换电柜", inputType: InputType.Cabinet },
          { name: "通道", inputType: InputType.Room }
        ],
        show: false,
        code: ""
      }
    };
  },
  created() {
    this.$readyWx(this.$axios, ["scanQRCode"]);
  },
  methods: {
    runSearch() {
      const inputType = this.inputType;
      if (inputType == InputType.Charger) {
        this.$toUrl("/web/manager/manager_switch_cabinet_information?roomId=" + this.chargerCode);
      } else if (inputType == InputType.Cabinet) {
        this.$toUrl("/web/manager/manager_switch_cabinet_information?qrCode=" + this.cabinetCode);
      } else if (inputType == InputType.Box2) {
        this.$toUrl("/web/manager/manager_switch_cabinet_information?box2Id=" + this.box2Code);
      } else if (inputType == InputType.Room) {
        this.$toUrl("/web/manager/manager_switch_cabinet_information?roomQrCode=" + this.roomCode);
      } else {
        let code = "";
        switch (inputType) {
          case InputType.Ebike:
            code = this.ebikeCode;
            break;
          case InputType.Controller:
            code = this.controllerCode;
            break;
          case InputType.Battery:
            code = this.batteryCode;
            break;
        }
        localStorage.setItem("inputType", inputType + "");
        localStorage.setItem("inputValue", code);
        localStorage.setItem("searchType", "0");
        this.$toUrl("/web/manager/device_detail");
      }
    },
    scanCode() {
      this.$scanCode((content: string) => {
        if (!content.startsWith("http")) {
          this.codeAction.code = content;
          this.codeAction.show = true;
          return;
        }
        const url = new URL(content);
        const code = this.splitCodeContent(content);
        if (!code) return;
        if (url.hostname == "m.hzdk.com" || url.hostname == "m.letfungo.com") {
          this.inputType = InputType.Ebike;
          this.ebikeCode = code;
        } else if (url.hostname == "app.hzdk.com") {
          this.inputType = InputType.Room;
          this.roomCode = code;
        } else {
          if (url.pathname.endsWith("cabinet")) {
            this.inputType = InputType.Cabinet;
            this.cabinetCode = code;
          } else if (url.pathname.endsWith("room")) {
            this.inputType = InputType.Room;
            this.roomCode = code;
          } else if (url.pathname.endsWith("ebike")) {
            this.inputType = InputType.Ebike;
            this.ebikeCode = code;
          } else {
            this.codeAction.code = code;
            this.codeAction.show = true;
          }
        }
      });
    },
    onCodeActionSelect(action: { inputType: number }) {
      this.inputType = action.inputType;
      const code = this.codeAction.code;
      this.updateCode(code);
    },
    updateCode(code: string) {
      switch (this.inputType) {
        case InputType.Ebike:
          this.ebikeCode = code;
          break;
        case InputType.Controller:
          this.controllerCode = code;
          break;
        case InputType.Battery:
          this.batteryCode = code;
          break;
        case InputType.Charger:
          this.chargerCode = code;
          break;
        case InputType.Box2:
          this.box2Code = code;
          break;
        case InputType.Cabinet:
          this.cabinetCode = code;
          break;
        case InputType.Room:
          this.roomCode = code;
          break;
      }
    },
    scanCodeFor(inputType: InputType) {
      this.$scanCode((content: string) => {
        this.inputType = inputType;
        let code = content;
        if (inputType === InputType.Ebike || inputType === InputType.Cabinet || inputType === InputType.Room) {
          code = this.splitCodeContent(content);
        }
        this.updateCode(code);
      });
    },
    splitCodeContent(content: string) {
      const res = content.split("?c=");
      if (res.length == 2) {
        return res[1];
      } else {
        Toast.fail("二维码内容错误");
        return "";
      }
    }
  }
});
